<template>
  <section class="ps-section--account ls-section-account">
    <div class="">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  name: "BasePrescriptionMedication",
};
</script>

<style scoped></style>
